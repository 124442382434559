import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './common/guard/auth/auth.guard';
import { LoginGuard } from './common/guard/login/login.guard';

const routes: Routes = [
  {
    path: 'auth',
    // canActivate: [LoginGuard],
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '',
    redirectTo: 'auth',
    // canActivate: [LoginGuard],
    pathMatch: 'full',
  },
  {
    path: 'newtimeentry',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./newtimeentry/newtimeentry.module').then(
        (m) => m.NewtimeentryPageModule
      ),
  },
  {
    path: 'newtimeentry/:index',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./newtimeentry/newtimeentry.module').then(
        (m) => m.NewtimeentryPageModule
      ),
  },
  {
    path: 'payperiod',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./payperiod/payperiod.module').then((m) => m.PayperiodPageModule),
  },
  {
    path: 'myaccount',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./myaccount/myaccount.module').then((m) => m.MyaccountPageModule),
  },
  {
    path: 'timesheet',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./timesheet/timesheet.module').then((m) => m.TimesheetPageModule),
  },
  {
    path: 'timesheetaction',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./timesheetaction/timesheetaction.module').then(
        (m) => m.TimesheetactionPageModule
      ),
  },
  {
    path: 'manpower',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./manpower/manpower.module').then((m) => m.ManpowerPageModule),
  },
  {
    path: 'home',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'workorders',
    loadChildren: () =>
      import('./workorders/workorders.module').then(
        (m) => m.WorkordersPageModule
      ),
  },
  {
    path: 'purchaseorder',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./purchaseorder/purchaseorder.module').then(
        (m) => m.PurchaseorderPageModule
      ),
  },
  {
    path: 'communication',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./communication/communication.module').then(
        (m) => m.CommunicationPageModule
      ),
  },
  {
    path: 'communication-messages',
    loadChildren: () =>
      import('./communication-messages/communication-messages.module').then(
        (m) => m.CommunicationMessagesPageModule
      ),
  },
  {
    path: 'notifications',
    loadChildren: () =>
      import('./notifications/notifications.module').then(
        (m) => m.NotificationsPageModule
      ),
  },
  {
    path: 'forms',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./forms/forms.module').then((m) => m.FormsPageModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
