import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { CostCodesComponent } from './costcodes.component';
import { CostCodePage } from './categories/cost-code.page';

@NgModule({
  declarations: [CostCodesComponent, CostCodePage],
  providers: [],
  imports: [CommonModule, IonicModule],
  exports: [CostCodesComponent, CostCodePage],
})
export class CostCodeComponentModule {}
