import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import {
  FontAwesomeModule,
  FaIconLibrary,
} from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

import { BrMaskerModule } from 'br-mask';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RequestHttpInterceptor } from './common/interceptor/http.interceptor';
import { NumberPipe } from './pipe/number.pipe';
import { SortPipe } from './pipe/sort/sort.pipe';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { CameraProvider } from './common/services/cameraService';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { Constants } from './common/constants/constant';
import { CostCodeComponentModule } from './costcodes/costcodes.module';
import { FileOpener } from '@ionic-native/file-opener/ngx';

import { File } from '@ionic-native/file/ngx';

const config: SocketIoConfig = { url: Constants.SOCKET_URL, options: {} };

@NgModule({
  declarations: [AppComponent, NumberPipe, SortPipe],
  entryComponents: [],
  imports: [
    FormsModule,
    CostCodeComponentModule,
    FontAwesomeModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    CommonModule,
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    BrMaskerModule,
    SocketIoModule.forRoot(config),
  ],
  providers: [
    StatusBar,
    SplashScreen,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestHttpInterceptor,
      multi: true,
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    NumberPipe,
    OneSignal,
    CameraProvider,
    File,
    FileOpener,
  ],
  bootstrap: [AppComponent],
  exports: [NumberPipe],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, fab, far);
  }
}
