import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController, NavParams } from '@ionic/angular';
import { HelperService } from 'src/app/common/services/helper.service';
import { AuthService } from 'src/app/common/services/auth.service';
import { HttpBaseService } from 'src/app/common/services/http-base.service';
import { Constants } from 'src/app/common/constants/constant';
import { IonInfiniteScroll } from '@ionic/angular';

@Component({
  selector: 'app-cost-code',
  templateUrl: './cost-code.page.html',
  styleUrls: ['./cost-code.page.scss'],
})
export class CostCodePage {
  search = '';
  categories: any[];
  isCostCode: true;
  isCostCodeFavorite: number;
  toasterMsg = '';
  employeeId: number;
  addRemoveCostCodeToFav: any;

  constructor(
    public modalCtrl: ModalController,
    public http: HttpBaseService,
    public params: NavParams,
    public util: HelperService,
    public auth: AuthService,
    public route: ActivatedRoute
  ) {
    this.getUserInfo();
    this.util.allCostCodeSub.subscribe((response) => {
      this.categories = response;
    });
    let test = this.employeeId;
    const costCodes = this.params.get('codes');
    this.util.allCostCodeSub.next(costCodes);

    this.isCostCode = this.params.get('isCostCode');
  }

  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  filterList(event) {
    const searchTerm = event.srcElement.value;

    if (!searchTerm) {
      return;
    }

    this.categories = this.categories.filter((keyword) => {
      if (keyword && searchTerm) {
        let costCodeName = keyword.name + ' ' + keyword.catnum;
        return (
          costCodeName.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
        );
      }
    });
  }

  addRemoveFav(favcode, isFav) {
    this.util.showLoading();
    if (isFav == 1) {
      favcode.is_favorite = 0;
      this.isCostCodeFavorite = 0;
      this.toasterMsg = 'Cost Code successfully removed from favorites.';
    } else {
      favcode.is_favorite = 1;
      this.isCostCodeFavorite = 1;
      this.toasterMsg = 'Cost Code successfully added to favorites.';
    }

    this.addRemoveCostCodeToFav = {
      employee_id: this.employeeId,
      is_cost_code_favorite: this.isCostCodeFavorite,
    };
    this.http
      .addRemoveCostCodeToFav(this.addRemoveCostCodeToFav, favcode.cost_code_id)
      .subscribe((res) => {
        this.util.showInfoBox(this.toasterMsg);
        //this.getFavCostCodes(this.employeeId, this.company_number, this.job_id);
        //this.getCostCodes();
        //this.getExtraCostCodes();
        this.util.hideLoading();
      });
  }

  getUserInfo() {
    this.auth.getUserInfo().then((res) => {
      this.employeeId = res.id;
    });
  }

  dismissModal(data) {
    this.modalCtrl.dismiss(data);
  }
}
