import { Injectable } from '@angular/core';
import { LoadingController, ToastController, Platform } from '@ionic/angular';
import { HttpBaseService } from './http-base.service';
import { Observable, Subject } from 'rxjs';
import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
// import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import {
  Plugins,
  FilesystemDirectory,
  Filesystem,
  FilesystemEncoding,
} from '@capacitor/core';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  allJobSub = new Subject<any>();
  favJobSub = new Subject<any>();
  allCostCodeSub = new Subject<any>();
  allPhase = new Subject<any>();
  extraCostCodeSub = new Subject<any>();
  favCostCodeSub = new Subject<any>();

  manPowerNotes = new Subject<any>();
  manPowerCrew = new Subject<any>();

  timeSheetURL = new Subject<any>();
  payPeriodURL = new Subject<any>();
  isAppInBackground = new Subject<any>();

  comingFromPageAddEquipement: boolean = false;
  createdEquipmentId: '';

  allVendorSub = new Subject<any>();
  defaultVendorSub = new Subject<any>();
  FILE_EXTENSION_HEADERS = {
    aac: 'audio/aac',
    abw: 'application/x-abiword',
    arc: 'application/x-freearc',
    avi: 'video/x-msvideo',
    azw: 'application/vnd.amazon.ebook',
    bin: 'application/octet-stream',
    bmp: 'image/bmp',
    bz: 'application/x-bzip',
    bz2: 'application/x-bzip2',
    csh: 'application/x-csh',
    css: 'text/css',
    csv: 'text/csv',
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    eot: 'application/vnd.ms-fontobject',
    epub: 'application/epub+zip',
    gif: 'image/gif',
    htm: 'text/html',
    html: 'text/html',
    ico: 'image/vnd.microsoft.icon',
    ics: 'text/calendar',
    jar: 'application/java-archive',
    jpeg: 'image/jpeg',
    jpg: 'image/jpeg',
    js: 'text/javascript',
    json: 'application/json',
    jsonld: 'application/ld+json',
    mid: 'audio/midi',
    midi: 'audio/midi',
    mjs: 'text/javascript',
    mp3: 'audio/mpeg',
    mpeg: 'video/mpeg',
    mpkg: 'application/vnd.apple.installer+xml',
    odp: 'application/vnd.oasis.opendocument.presentation',
    ods: 'application/vnd.oasis.opendocument.spreadsheet',
    odt: 'application/vnd.oasis.opendocument.text',
    oga: 'audio/ogg',
    ogv: 'video/ogg',
    ogx: 'application/ogg',
    otf: 'font/otf',
    png: 'image/png',
    pdf: 'application/pdf',
    ppt: 'application/vnd.ms-powerpoint',
    pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    rar: 'application/x-rar-compressed',
    rtf: 'application/rtf',
    sh: 'application/x-sh',
    svg: 'image/svg+xml',
    swf: 'application/x-shockwave-flash',
    tar: 'application/x-tar',
    tif: 'image/tiff',
    tiff: 'image/tiff',
    ttf: 'font/ttf',
    txt: 'text/plain',
    vsd: 'application/vnd.visio',
    wav: 'audio/wav',
    weba: 'audio/webm',
    webm: 'video/webm',
    webp: 'image/webp',
    woff: 'font/woff',
    woff2: 'font/woff2',
    xhtml: 'application/xhtml+xml',
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    xml: 'application/xml&nbsp;',
    xul: 'application/vnd.mozilla.xul+xml',
    zip: 'application/zip',
    '3gp': 'video/3gpp',
    '3g2': 'video/3gpp2',
    '7z': 'application/x-7z-compressed',
  };

  userRoles: any;
  constructor(
    public loadingController: LoadingController,
    public toastController: ToastController,
    public http: HttpBaseService,
    public file: File,
    public fileOpener: FileOpener,
    public platform: Platform
  ) {}

  showLoading(duration = 2000, message = 'Please wait...') {
    const loading = this.loadingController.create({
      message,
      duration,
    });

    loading.then((res) => {
      res.present();
    });
  }

  hideLoading() {
    this.loadingController
      .dismiss()
      .then((res) => {
        console.log('Loading dismissed!', res);
      })
      .catch((error) => {
        console.log('error', error);
      });
  }

  showInfoBox(message) {
    this.toastController
      .create({
        message,
        color: 'light',
        duration: 5000,
      })
      .then((res) => {
        res.present();
      });
  }

  setUserRoles() {
    this.http.getAllRoles().subscribe((res) => {
      this.userRoles = res['data'];
    });
  }

  getUserRoles() {
    return this.userRoles;
  }

  convertMinutesToHrs(time, html = '') {
    if (time !== null) {
      if (time.toString().length > 5) {
        time =
          time.toString().split('T')[1].split(':')[0] +
          ':' +
          time.split('T')[1].split(':')[1];
      }

      let hours = time.toString().replace(':', '.');
      if (html !== '') {
        return hours;
      }
      let hrs = hours.split('.')[0];
      let minut = hours.split('.')[1] / 60;
      let totalHrs = hrs * 1 + minut * 1;
      // console.log(totalHrs);
      if (totalHrs || totalHrs == 0) {
        return totalHrs;
      } else {
        return time;
      }
    }
  }

  convertHrsToMinutes(time) {
    let zero = '';
    let hours = time.replace('.', ':');

    let hrs = hours.split(':')[0];
    let minut: any = 0 + '.' + hours.split(':')[1];
    let mint = minut * 60;
    let totalHrs = hrs * 1 + ':' + mint * 1;
    if (mint.toString().length === 1) {
      zero = '0';
    }
    return totalHrs + '' + zero;
  }

  public static isValidVal(variable: any): boolean {
    if (
      typeof variable === 'undefined' ||
      variable === null ||
      (typeof variable === 'string' &&
        variable.trim !== undefined &&
        variable.trim() === '')
    ) {
      return false;
    }
    return true;
  }

  async openDownloadedFile(res, file = '', ext = '') {
    if (this.platform.is('cordova')) {
      let filePath = '';

      if (this.platform.is('ios')) {
        filePath = this.file.documentsDirectory;
        // filePath = Directory.Library;
      } else {
        filePath = this.file.dataDirectory;
      }

      let fileName = file == '' ? res.filename : file;
      let fileExtension =
        ext == ''
          ? res.mime_type.toString().toLowerCase()
          : ext.toString().toLowerCase();
      let mimeType =
        this.FILE_EXTENSION_HEADERS[fileExtension] !== undefined
          ? this.FILE_EXTENSION_HEADERS[fileExtension]
          : 'text/plain';

      const byteArray = new Uint8Array(
        atob(res.data.body)
          .split('')
          .map((char) => char.charCodeAt(0))
      ); //decode the base64string
      let downloadedFile = new Blob([byteArray], { type: mimeType });

      await this.createFile(filePath, fileName);
      await this.writeToFile(downloadedFile, filePath, fileName);

      if (fileExtension == 'pdf') {
        //show chooser for pdf files
        await this.fileOpener.showOpenWithDialog(filePath + fileName, mimeType);
      } else {
        //otherwise just try to open with default viewer
        await this.fileOpener.open(filePath + fileName, mimeType);
      }
    } else {
      const linkSource = `data:application;base64,${res.data.body}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = file == '' ? res.filename : file;
      downloadLink.click();
    }
  }

  async createFile(filePath, fileName) {
    return this.file.createFile(filePath, fileName, false).catch((err) => {
      console.log('filecreated');
      console.error(err);
    });
  }

  async writeToFile(downloadedFile, filePath, fileName) {
    return this.file
      .writeFile(filePath, fileName, downloadedFile, {
        replace: true,
        append: false,
      })
      .then((createdFile) => {
        console.log('File written successfully.');
        console.log(createdFile);
      })
      .catch((err) => {
        console.error(err);
      });
  }
}
