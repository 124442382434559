import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';
import { Observable, from } from 'rxjs';
import { throwError } from 'rxjs';
import { NavController } from '@ionic/angular';
import { HttpBaseService } from '../services/http-base.service';
import { Constants } from '../../common/constants/constant';
import { AuthService } from '../services/auth.service';
import { HelperService } from '../services/helper.service';
import { Router } from '@angular/router';

@Injectable()
export class RequestHttpInterceptor implements HttpInterceptor {
  flagForAlert = false;
  constructor(
    private router: Router,
    private navCtrl: NavController,
    public httpService: HttpBaseService,
    public authService: AuthService,
    public util: HelperService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.authService.getToken()).pipe(
      switchMap((token) => {
        // token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjc0MjIsImlhdCI6MTU5OTQ4NjYzNCwiZXhwIjoxNTk5NDkwMjM0fQ.bu0xEjm2kVetaKU1pjxfsVVk7HVAwpuXIQOHtOV5MAY'
        if (token && req.url != Constants.API_URL + '/users/profile/') {
          req = req.clone({
            setHeaders: {
              Authorization: 'Bearer ' + token,
            },
          });
        }

        if (
          !req.headers.has('Content-Type') &&
          req.url != Constants.API_URL + '/users/profile/' &&
          req.url != Constants.API_URL + '/workOrderAttachment/create/' &&
          req.url != Constants.API_URL + '/workOrder/completeWorkOrder/'
        ) {
          req = req.clone({
            setHeaders: {
              'content-type': 'application/json',
            },
          });
        }

        if (
          req.url != Constants.API_URL + '/users/profile/' &&
          req.url != Constants.API_URL + '/workOrder/completeWorkOrder/' &&
          req.url != Constants.API_URL + '/workOrderAttachment/create/'
        ) {
          req = req.clone({
            headers: req.headers.set('Accept', 'application/json'),
          });
        }

        return next.handle(req).pipe(
          map((event) => {
            return event;
          }),
          catchError((err) => {
            // console.log(err);
            if (err.status === Constants.UNAUTHORISED_STATUS) {
              if (err.error.message === Constants.INVALID_TOKEN) {
                this.util.showInfoBox(Constants.TOKEN_EXPIRED);
                this.authService.clearStorage();
                this.navCtrl.navigateRoot('/');
              }
            }
            return throwError(err);
          }),
          finalize(() => {})
        );
      })
    );
  }
}
