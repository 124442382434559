import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActionSheetController, AlertController } from '@ionic/angular';
//  import {Camera,CameraOptions } from '@ionic-native/camera/ngx';

import { Plugins, CameraResultType, CameraSource } from '@capacitor/core';
const { Camera } = Plugins;

@Injectable()
export class CameraProvider {
  //public camera:Camera,public config:Config,
  appName = 'MCM App';
  isWeb: any;
  constructor(
    public http: HttpClient,
    // public camera:Camera,
    public actionSheetCtrl: ActionSheetController,
    public alertCtrl: AlertController
  ) {}

  open(cb) {
    this.presentActionSheet(cb);
  }

  async presentActionSheet(cb) {
    let buttons: any;
    if (this.isWeb) {
      buttons = [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            console.log('Destructive clicked');
          },
        },
        {
          text: 'Gallery',
          handler: () => {
            this.openNative2(CameraSource.Photos, cb);
          },
        },
      ];
    } else {
      buttons = [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            console.log('Destructive clicked');
          },
        },
        {
          text: 'Camera',
          handler: () => {
            this.openNative2(CameraSource.Camera, cb);
          },
        },
        {
          text: 'Gallery',
          handler: () => {
            this.openNative2(CameraSource.Photos, cb);
          },
        },
      ];
    }
    const actionSheet = await this.actionSheetCtrl.create({
      header: 'Select type',
      buttons: buttons,
    });
    await actionSheet.present();
  }

  async openNative(types, cb) {
    const capturedPhoto = await Camera.getPhoto({
      resultType: CameraResultType.DataUrl,
      source: types,
      quality: 20,
      correctOrientation: true,
      saveToGallery: true,
      allowEditing: false,
    })
      .then((imageData) => {
        console.log(imageData);
        let base64Image = imageData.dataUrl;
        let data = {
          image: base64Image,
          file: this.convertToFile(base64Image),
        };
        cb(data);
      })
      .catch((err) => {
        console.log('camera err', err);
      });
  }

  convertToFile(dataURI) {
    let blob = this.dataURItoBlob2(dataURI);
    // let file = new File([blob], 'image.jpeg', {type: "'image/jpeg"});
    return blob;
  }

  async openNative2(source, cb) {
    const image = await Camera.getPhoto({
      quality: 50,
      resultType: CameraResultType.DataUrl,
      source: source,
    })
      .then((img) => {
        let ori: any;
        // this.compresor.compressFile(img.dataUrl, ori, 50, 50).then(imageC => {
        //   // console.log(imageC)
        let img2 = img.dataUrl.split(',')[1];
        const blobData = this.b64toBlob(img2, `image/${img.format}`);
        cb(blobData);
        // })
      })
      .catch((err) => {
        console.log('camera err', err);
      });
  }

  b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  dataURItoBlob2(dataURI) {
    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpeg' });
  }

  digitalSignature(base64, cb) {
    var byteString = atob(base64.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    cb(new Blob([ab], { type: 'image/jpeg' }));
  }

  async permissionConfirm(msg: string, callback) {
    let alert = await this.alertCtrl.create({
      header: this.appName,
      message: msg,
      buttons: [
        {
          text: 'NOT NOW',
          role: 'cancel',
          handler: () => {
            callback(false);
            console.log('Cancel clicked');
          },
        },
        {
          text: 'SETTINGS',
          handler: () => {
            callback(true);
            console.log('Buy clicked');
          },
        },
      ],
    });
    await alert.present();
  }
}
