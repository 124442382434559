import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  loggedIn: boolean = false;

  constructor(private router: Router, private authService: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((resolve, reject) => {
      this.authService.getUserInfo().then((res) => {
        this.loggedIn = res;
        if (!this.loggedIn) {
          this.router.navigate(['/auth/login']);
          resolve(false);
        }
        resolve(true);
      });
    });
  }

  public getGuardAuthentication(): boolean {
    return this.loggedIn;
  }
}
