import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { Constants } from '../../common/constants/constant';
import { AuthService } from './auth.service';
import { ChannelModel } from '../models/channel.model';
import { MessageModel } from '../models/message.model';
import { NotificationModel } from '../models/notification.model';
import { ChannelGroupModel } from '../models/channel-group.model';

@Injectable({
  providedIn: 'root',
})
export class HttpBaseService {
  constructor(private http: HttpClient, private authServ: AuthService) {}

  login(
    email: string,
    password: string,
    device_token = '12345',
    device_type: string
  ): Observable<any> {
    if (device_token == null) {
      device_token = '';
    }
    const body = { username: email, password, device_token, device_type };
    // const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(Constants.API_URL + '/login/', body);
  }

  forgot(email: string): Observable<any> {
    const body = { url: Constants.FORGOT_PASSWORD_URL, email_address: email };
    return this.http.post(Constants.API_URL + '/users/forgot_password/', body);
  }

  sedOTP(moileNumber) {
    const body = { phone: moileNumber.toString() };
    return this.http.post(
      Constants.API_URL + '/users/temporary_password',
      body
    );
  }

  getAllUsers() {
    return this.http.post(Constants.API_URL + '/users', '');
  }

  getEntryTypes() {
    return this.http.get(Constants.API_URL + '/time_entry/entryTypes');
  }

  getJob(id) {
    return this.http.get(Constants.API_URL + '/time_entry/' + id);
  }

  getAllJobs(data) {
    return this.http.post(Constants.API_URL + '/jobs', data);
  }

  getJobTickets(jobId) {
    return this.http.get(Constants.API_URL + `/jobs/getTickets/${jobId}`);
  }

  getAllEquipment() {
    return this.http.get(Constants.API_URL + '/equipment');
  }

  addRemoveJobToFav(data, jobId) {
    return this.http.post(
      Constants.API_URL + '/jobs/favoriteJob/' + jobId,
      data
    );
  }

  getFavJobs(userId, company_number) {
    return this.http.post(Constants.API_URL + '/jobs/favJob/' + userId, {
      company_number,
    });
  }

  getAllCostCodes(department_id, job_id, company_number) {
    return this.http.post(Constants.API_URL + '/categories', {
      department_id,
      job_id,
      company_number,
    });
  }

  getAllCategoriesCostCode(job_id, company_number, phasenum, department_id?) {
    return this.http.post(Constants.API_URL + '/categories/CostCode', {
      job_id,
      company_number,
      phasenum,
      department_id,
    });
  }

  getCostCodes(department_id, job_id, company_number) {
    return this.http.post(Constants.API_URL + '/categories/mobileCostCode', {
      department_id,
      job_id,
      company_number,
    });
  }

  getPhases(job_id, company_number, cost_type = null) {
    return this.http.post(Constants.API_URL + '/categories/Phases', {
      job_id,
      company_number,
      cost_type,
    });
  }

  getExtraCategories(job_id, company_number, phasenum, catnum) {
    return this.http.post(Constants.API_URL + '/categories/extras', {
      job_id,
      company_number,
      phasenum,
      catnum,
    });
  }

  getExtraCostCodes(department_id, job_id, company_number) {
    return this.http.post(
      Constants.API_URL + '/categories/mobile/extraCostCode',
      { department_id, job_id, company_number }
    );
  }

  getAllLocals() {
    return this.http.get(Constants.API_URL + '/unionlocals');
  }

  getJobLocals(ce_job_id, union_id) {
    return this.http.post(Constants.API_URL + '/jobs/locals', {
      ce_job_id,
      union_id,
    });
  }

  addRemoveCostCodeToFav(data, costCodeId) {
    return this.http.post(
      Constants.API_URL + '/categories/favoriteCost_code/' + costCodeId,
      data
    );
  }

  getFavCostCodes(userId, company_number, job_id) {
    return this.http.post(Constants.API_URL + '/categories/favCode/' + userId, {
      company_number,
      job_id,
    });
  }

  getAllDepartment(data) {
    return this.http.post(Constants.API_URL + '/departments', data);
  }

  saveTimeEntry(data) {
    return this.http.post(Constants.API_URL + '/time_entry/add/', data);
  }

  updateTimeEntry(id, data) {
    return this.http.put(Constants.API_URL + '/time_entry/update/' + id, data);
  }

  updateNote(id, data) {
    return this.http.put(
      Constants.API_URL + '/time_entry/updateNotes/' + id,
      data
    );
  }

  createAttachment(data) {
    return this.http.post(
      Constants.API_URL + '/workOrderAttachment/create/',
      data
    );
  }

  workComplete(data) {
    return this.http.post(
      Constants.API_URL + '/workOrder/completeWorkOrder/',
      data
    );
  }

  getAuthenticationUser(userdata) {
    return this.http.get(Constants.API_URL + '/users/' + userdata.userId);
  }

  saveProfile(data, token) {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + token,
    });
    return this.http.post(Constants.API_URL + '/users/profile/', data, {
      headers,
    });
  }

  getUser(empId) {
    return this.http.get(Constants.API_URL + '/users/' + empId);
  }

  getAllRoles() {
    return this.http.get(Constants.API_URL + '/roles/');
  }

  newJobRequest(data) {
    return this.http.post(Constants.API_URL + '/jobs/createJob', data);
  }

  getDailyTimesheet(entry_date) {
    return this.http.post(Constants.API_URL + '/daily_timesheet', {
      entry_date,
    });
  }

  fetchDailyMyjobs(entry_date) {
    return this.http.post(Constants.API_URL + '/daily_timesheet/myJobs', {
      entry_date,
    });
  }

  fetchTimesheetDetails(employee_id, entry_date) {
    return this.http.post(Constants.API_URL + '/daily_timesheet/byEmployee/', {
      entry_date,
      employee_id,
    });
  }

  fetchTimeEntriesByEmployeeAndDate(postData) {
    return this.http.post(
      Constants.API_URL + '/time_entry/empTimeEntry',
      postData
    );
  }

  fetchTimesheetMyJobDetails(job_id, entry_date) {
    return this.http.post(
      Constants.API_URL + '/daily_timesheet/myJobsDetails/',
      { entry_date, job_id }
    );
  }

  fetchTimesheetDetailsForUser(empId, entry_date) {
    return this.http.post(
      Constants.API_URL + '/daily_timesheet/regularEmployee/' + empId,
      { entry_date }
    );
  }

  getPayperiod(data) {
    return this.http.post(Constants.API_URL + '/pay_period/', data);
  }

  getPayperiodForUser(empId, data) {
    return this.http.post(
      Constants.API_URL + '/pay_period/regularEmployee/' + empId,
      data
    );
  }

  getPayperiodDetail(data) {
    return this.http.post(Constants.API_URL + '/pay_period/byEmployee/', data);
  }

  getJobData(data): Observable<any> {
    let jobs = this.http.post(Constants.API_URL + '/jobs/', data);
    return forkJoin([jobs]);
  }

  duplicateTimeEntry(id) {
    return this.http.put(Constants.API_URL + '/time_entry/duplicate/' + id, '');
  }

  deleteTimeEntry(id) {
    return this.http.delete(Constants.API_URL + '/time_entry/delete/' + id);
  }

  getManPowerJob(data) {
    return this.http.post(Constants.API_URL + '/manpowers/mobile/jobs', data);
  }

  getJobNoteCount(jobId) {
    const data = { page: '1', pageItems: '20' };
    return this.http.post(
      Constants.API_URL + '/manpowers/job/noteCount/' + jobId,
      data
    );
  }

  getassignedEmpToJob(jobId) {
    const data = { page: '1', pageItems: '20', job_id: jobId };
    return this.http.post(
      Constants.API_URL + '/manpowers/mobile/job/assign-employees',
      data
    );
  }

  removeEmpReq(data) {
    return this.http.post(
      Constants.API_URL + '/manpowers/employee/performance/notes/add',
      data
    );
  }

  sendMsgToMPTeam(data) {
    return this.http.post(Constants.API_URL + '/manpowers/job/notes/add', data);
  }

  getJobAllNotes(data) {
    return this.http.post(
      Constants.API_URL +
        '/manpowers/job/notes/' +
        data.jobId +
        '?t=' +
        new Date().getTimezoneOffset(),
      data
    );
  }

  getSingalNoteDetail(id) {
    return this.http.post(
      Constants.API_URL +
        '/manpowers/job/note-details/' +
        id +
        '?t=' +
        new Date().getTimezoneOffset(),
      ''
    );
  }

  jobNoteResolved(noteId) {
    return this.http.post(
      Constants.API_URL + '/manpowers/job/note/resolve/' + noteId,
      ''
    );
  }

  checkIsDateLocked(date) {
    return this.http.post(
      Constants.API_URL + '/pay_period_lockouts/get_lock_status',
      { date }
    );
  }

  getWorkOrder(
    searchPage,
    pageItems,
    text,
    work_order_type,
    status,
    sortByDate,
    employee_id
  ) {
    return this.http.post(Constants.API_URL + '/workOrder/getAllWorkOrder', {
      searchPage,
      pageItems,
      text,
      work_order_type,
      status,
      sortByDate,
      employee_id,
    });
  }

  getOrderTypes() {
    return this.http.get(Constants.API_URL + '/workOrder/workOrderTypes');
  }

  getOrderSummary(work_order_id) {
    return this.http.post(Constants.API_URL + '/workOrder/getOrderByid', {
      work_order_id,
    });
  }

  getPurchaseOrders(work_order_id, employee_id, searchPage, pageItems) {
    return this.http.post(Constants.API_URL + '/workOrder/getPurchaseOrder', {
      work_order_id,
      employee_id,
      searchPage,
      pageItems,
    });
  }

  updatePurchaseOrder(formdata) {
    return this.http.post(
      Constants.API_URL + '/workOrder/updatePurchaseOrder',
      formdata
    );
  }

  getPODetail(work_order_purchase_order) {
    return this.http.post(Constants.API_URL + '/workOrder/purchaseOrder', {
      work_order_purchase_order,
    });
  }

  getTruckStockByWorkOrderId(work_order_id, searchPage, pageItems) {
    return this.http.post(Constants.API_URL + '/truckStock/byWorkOrderId', {
      work_order_id,
      searchPage,
      pageItems,
    });
  }

  getAllTruckStocks(ce_department_id, companynum) {
    return this.http.post(Constants.API_URL + '/truckStock', {
      ce_department_id,
      companynum,
    });
  }

  createTruckStock(formdata) {
    return this.http.post(Constants.API_URL + '/truckStock', formdata);
  }

  updateTruckStock(formdata) {
    return this.http.post(Constants.API_URL + '/truckStock/update', formdata);
  }

  deleteTruckStock(work_order_truck_stock_id) {
    return this.http.post(Constants.API_URL + '/truckStock/remove', {
      work_order_truck_stock_id,
    });
  }

  deleteAttachment(work_order_attachment_id) {
    return this.http.post(Constants.API_URL + '/workOrderAttachment/remove', {
      work_order_attachment_id,
    });
  }

  getLaborsByWorkOrderId(work_order_id, searchPage, pageItems) {
    return this.http.post(Constants.API_URL + '/laborWorkOrder/byWorkOrder', {
      work_order_id,
      searchPage,
      pageItems,
    });
  }

  deleteLabor(work_order_labor_id) {
    return this.http.post(Constants.API_URL + '/laborWorkOrder/delete', {
      work_order_labor_id,
    });
  }

  createLabor(formdata) {
    return this.http.post(
      Constants.API_URL + '/laborWorkOrder/create',
      formdata
    );
  }

  updateLabor(formdata) {
    return this.http.post(
      Constants.API_URL + '/laborWorkOrder/update',
      formdata
    );
  }

  getAttachmentsByWorkOrderId(work_order_id, searchPage, pageItems) {
    return this.http.post(
      Constants.API_URL + '/workOrderAttachment/byworkOrderId',
      { work_order_id, searchPage, pageItems }
    );
  }

  attchmentDtl(work_order_attachment_id) {
    return this.http.post(Constants.API_URL + '/workOrderAttachment', {
      work_order_attachment_id,
    });
  }

  getAllEquipments(data) {
    return this.http.post(
      Constants.API_URL + '/workOrder/getEquipmentSiteById',
      data
    );
  }

  getEquipment(data) {
    return this.http.post(
      Constants.API_URL + '/workOrder/getEquipmentByid',
      data
    );
  }

  updateWorkOrder(data) {
    return this.http.post(
      Constants.API_URL + '/workOrder/updateWorkOrder',
      data
    );
  }

  createEquipment(formdata) {
    return this.http.post(
      Constants.API_URL + '/workOrder/createEquipment',
      formdata
    );
  }

  getLabor(data) {
    return this.http.post(Constants.API_URL + '/laborWorkOrder/', data);
  }

  getTruckStockById(data) {
    return this.http.post(Constants.API_URL + '/truckStock/byId/', data);
  }

  getAllPurchaseOrders(data) {
    return this.http.post(Constants.API_URL + '/purchaseOrders', data);
  }

  createPurchaseOrder(data) {
    return this.http.post(Constants.API_URL + '/purchaseOrders/create', data);
  }

  getPurchaseOrder(id) {
    return this.http.get(Constants.API_URL + '/purchaseOrders/' + id);
  }

  updatepurchaseOrders(id, data) {
    return this.http.put(
      Constants.API_URL + '/purchaseOrders/update/' + id,
      data
    );
  }

  getAllVendors(data) {
    return this.http.post(Constants.API_URL + '/vendors', data);
  }

  newVendorRequest(data) {
    return this.http.post(Constants.API_URL + '/vendors/createVendor', data);
  }

  getAllChannels(data: { userId: number }): Observable<ChannelModel[]> {
    const url = `${Constants.API_URL}/channel/${data.userId}`;
    return this.http.get<ChannelModel[]>(url);
  }

  getAllMessages(data: {
    channelId: number;
    userId: number;
  }): Observable<MessageModel[]> {
    const url = `${Constants.API_URL}/channel/messages`;
    const reqData = {
      channelId: data.channelId,
      userId: data.userId,
    };
    return this.http.post<MessageModel[]>(url, reqData);
  }

  createMessage(data: {
    channelId: number;
    userId: number;
    msg: string;
    receiptRequired: boolean;
    fileUrl: string;
    interactionMsg: string;
    checkMsg: string;
  }): Observable<MessageModel[]> {
    const url = `${Constants.API_URL}/channel/message`;
    const reqData = {
      channel_id: data.channelId,
      user_id: data.userId,
      message: data.msg,
      receipt_required: data.receiptRequired,
      file_url: data.fileUrl,
      interaction_message: data.interactionMsg,
      check_message: data.checkMsg,
    };
    return this.http.post<MessageModel[]>(url, reqData);
  }

  // Note this is not a push notification
  createNotifications(
    data: {
      msg: string;
      msg_header: string;
      type: string;
      channel_id: number;
      user_id: number;
      is_read: boolean;
      sent_by_user_id: number;
    }[]
  ): Observable<MessageModel[]> {
    const url = `${Constants.API_URL}/channel/notification`;
    return this.http.post<MessageModel[]>(url, data);
  }

  createMessageReadReceipt(data: {
    msgId: number;
    userId: number;
  }): Observable<MessageModel[]> {
    const url = `${Constants.API_URL}/channel/message/readReceipt`;
    const reqData = {
      id: data.msgId,
      user_id: data.userId,
    };
    return this.http.post<MessageModel[]>(url, reqData);
  }

  getAllNotifications(data: {
    userId: number;
  }): Observable<NotificationModel[]> {
    const url = `${Constants.API_URL}/channel/notification/${data.userId}`;
    return this.http.get<NotificationModel[]>(url);
  }

  readNotification(data: {
    user_id: number;
    notification_ids: number[];
  }): Observable<MessageModel[]> {
    const url = `${Constants.API_URL}/channel/notification/read`;
    return this.http.post<MessageModel[]>(url, data);
  }

  deleteNotification(data: {
    user_id: number;
    notification_ids: number[];
  }): Observable<MessageModel[]> {
    const url = `${Constants.API_URL}/channel/notification/delete`;
    return this.http.post<MessageModel[]>(url, data);
  }

  createPushNotification(
    data: {
      msg: string;
      msg_header: string;
      type: string;
      channel_id: number;
      user_id: number;
      is_read: boolean;
      sent_by_user_id: number;
    }[]
  ): Observable<MessageModel[]> {
    const url = `${Constants.API_URL}/channel/push-notification`;
    return this.http.post<MessageModel[]>(url, { pushNotifications: data });
  }

  getChannelGroups(): Observable<ChannelGroupModel[]> {
    const url = `${Constants.API_URL}/channel-groups`;
    return this.http.get<ChannelGroupModel[]>(url);
  }

  fetchCreateChannel(data: {
    name: string;
    type: string;
    jobId: number;
    userIds: number[];
  }): Observable<ChannelModel[]> {
    const url = `${Constants.API_URL}/channel`;
    return this.http.post<ChannelModel[]>(url, data);
  }

  //download an attachment from channel
  downloadAttachmentFromMessage(data) {
    return this.http.post(Constants.API_URL + '/channel/message/upload', data);
  }

  //upload an attachment to a message
  uploadAttachmentToMessage(data) {
    return this.http.post(Constants.API_URL + '/channel/message/uploads', data);
  }
  // forms API
  getAllDocuments(param, parentId) {
    const url = `${Constants.API_URL}/forms?search=${param}&parent_id=${parentId}`;
    return this.http.get(url);
  }

  getAllDocumentsByParentId(id) {
    const url = `${Constants.API_URL}/forms?parent_id=${id}`;
    return this.http.get(url);
  }

  downloadDocument(payload): Observable<HttpResponse<any>> {
    const url = `${Constants.API_URL}/forms/download`;
    return this.http.post<any>(url, payload);
  }
}
