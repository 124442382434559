import { Injectable } from '@angular/core';

@Injectable()
export class Constants {
  // ERROR MESSAGES
  public static API_ERROR = 'Sorry, Something went wrong, Please try again.';
  public static TOKEN_EXPIRED = 'Your session expired. Try to login again.';

  public static API_URL: string = 'https://api.mcm.spark-staging.dev/api/v1'; // Staging URL
  public static SOCKET_URL: string = 'https://api.mcm.spark-staging.dev';
  public static FILE_URL: string =
    'https://api.mcm.spark-staging.dev/public/images/';

  // public static API_URL: string = 'http://localhost:8004/api/v1'; // Local
  // public static SOCKET_URL: string = 'http://localhost:8004';
  // public static FILE_URL: string = 'http://localhost:8004/public/images/';

  // public static API_URL: string = 'https://app.api.mcm-team.com/api/v1'; // Live client
  // public static SOCKET_URL: string = 'https://app.api.mcm-team.com';
  // public static FILE_URL: string =
  //   'https://app.api.mcm-team.com/public/images/';

  public static FORGOT_PASSWORD_URL: string = 'app.mcm-team.com';

  // Error message variables
  // public static INVALID_TOKEN = 'Invalid token';
  public static INVALID_TOKEN = 'Session Expired!';

  // API STATUS
  public static UNAUTHORISED_STATUS = 401;
  public static SUCCESS_STATUS = 200;

  // Login Page
  public static REQUIRED_USERNAME_PASSWORD =
    'Username & Password fields are required';

  public static ONESIGNAL_KEY = '3b205aa3-57a7-4a4c-a6fd-69635caf3fd1';

  public static FIREBASE_ID = '447164844989';
}
