import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';
import { HttpBaseService } from '../common/services/http-base.service';
import { HelperService } from '../common/services/helper.service';
import { Constants } from 'src/app/common/constants/constant';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NavController } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { CostCodePage } from './categories/cost-code.page';
import { AuthService } from '../common/services/auth.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'costcodes',
  templateUrl: './costcodes.component.html',
  styleUrls: ['./costcodes.component.scss'],
})
export class CostCodesComponent
  implements OnChanges, OnInit, AfterViewInit, OnDestroy
{
  costcodes: FormGroup;
  @Input() formSubmitted: String;
  @Input() job_id: Number;
  @Input() work_order_id: Number;
  @Input() phase: String;
  @Input() costcode: Number;
  @Input() selectedCostCode: String;
  @Input() selectedCostCodeName: String;
  @Input() extra: Number;
  @Input() selectedExtra: String;
  @Input() selectedExtraName: String;
  @Input() default_ce_phase_id: Number;
  @Input() default_ce_phasenum: String;
  @Input() default_ce_phase_name: String;
  @Input() default_ce_category_id: Number;
  @Input() default_ce_category_name: String;
  @Input() default_ce_catnum: String;
  @Input() default_extra_ce_category_id: Number;
  @Input() default_ce_extranum: String;
  @Input() default_ce_extra_name: String;
  @Input() useMaterialPhases: boolean;
  @Input() userDepartmentId: Number;
  @Input() allFieldsRequired: boolean;

  phases: any = [];
  costCodes: any = [];
  extras: any = [];
  @Output() costCodeSelected = new EventEmitter<{
    costCodeId: number;
    costCodeName: string;
    catnum: string;
    extraNum: string;
  }>();
  @Output() phaseSelected = new EventEmitter<{ phase: any }>();

  companyNumber;
  areAllFieldsRequired = true;
  $ngUnsubscribe: Subject<any>;
  constructor(
    private http: HttpBaseService,
    private util: HelperService,
    public navCtrl: NavController,
    public modal: ModalController,
    public authServ: AuthService
  ) {}

  ngAfterViewInit() {
    this.areAllFieldsRequired = this.allFieldsRequired;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.areAllFieldsRequired = this.allFieldsRequired;
    if (changes.formSubmitted) {
      this.formSubmitted = changes.formSubmitted.currentValue;
    }

    if (changes.job_id) {
      this.job_id = Number(changes.job_id.currentValue);
      this.getPhases()?.subscribe();
    }
  }

  async getOrderSummary() {
    this.util.showLoading();
    if (!this.work_order_id) {
      return;
    }
    await this.http
      .getOrderSummary(this.work_order_id)
      .subscribe((response) => {
        this.companyNumber = response['data']?.ceJob_companyNumber;
        this.getPhases().subscribe();
      });
  }

  ngOnInit() {
    this.$ngUnsubscribe = new Subject<any>();
    this.costcodes = new FormGroup({
      phaseDropdown: new FormControl('', Validators.required),
      costcodeDropdown: new FormControl('', Validators.required),
      extraDropdown: new FormControl('', Validators.required),
    });
    // this.getOrderSummary();
    if (this.job_id && this.job_id != 0) {
      this.getPhases().subscribe();
    }

    if (!this.userDepartmentId) {
      this.getUserInfo();
    }

    if (this.default_ce_phasenum && !this.phase) {
      //load the default phase
      this.phase = this.default_ce_phasenum;
    }

    if (this.phase) {
      this.costcodes.patchValue({ phaseDropdown: this.phase });

      //get list of cost codes
      this.getCategories();

      if (this.default_ce_category_id && !this.costcode) {
        //load the default cost code
        this.costcode = this.default_ce_category_id;
        this.selectedCostCode = this.default_ce_catnum;
        this.selectedCostCodeName = this.default_ce_category_name;
      }

      if (this.costcode) {
        this.costcodes.patchValue({ costcodeDropdown: this.costcode });

        //get list of extras
        this.getExtras();
        if (this.default_extra_ce_category_id && !this.extra) {
          //load the default extra
          this.extra = this.default_extra_ce_category_id;
          this.selectedExtra = this.default_ce_extranum;
          this.selectedExtraName = this.default_ce_extra_name;
        }

        if (this.extra) {
          this.costcodes.patchValue({ extraDropdown: this.extra });
        }
      }
    }
  }

  getPhases(): Observable<any> {
    if (!this.job_id) {
      return;
    }
    return new Observable((resolve) => {
      this.http
        .getPhases(
          Number(this.job_id),
          this.companyNumber,
          this.useMaterialPhases ? 'M' : null
        )
        .subscribe(
          (response: any) => {
            this.util.hideLoading();
            this.phases = response.data;
            var test = 1;
            resolve.next();
            resolve.complete();
          },
          (err) => {
            this.util.hideLoading();
            if (err.status === Constants.UNAUTHORISED_STATUS) {
              this.util.showInfoBox(Constants.TOKEN_EXPIRED);
            } else {
              this.util.showInfoBox(Constants.API_ERROR);
            }
            resolve.next();
            resolve.complete();
          }
        );
    });
  }

  async selectCostCodeWithModal() {
    this.modal
      .create({
        component: CostCodePage,
        cssClass: 'my-custom-class',
        componentProps: {
          codes: this.costCodes,
          isCostCode: true,
        },
      })
      .then((modal) => {
        modal.present();
        modal.onDidDismiss().then((res) => {
          if (res.data !== '') {
            this.costcodes.patchValue({
              costcodeDropdown: res.data.cost_code_id,
            });
            if (res.data.catnum !== this.selectedCostCode) {
              this.extras = [];
            }
            this.selectedCostCode = res.data.catnum;
            this.selectedCostCodeName = res.data.name;
            //load extras
            this.getExtras();
            this.costcodes.patchValue({
              extraDropdown: res.data.cost_code_id,
            });

            this.selectedExtra = res.data.extranum;
            this.selectedExtraName = 'None';

            this.costCodeSelected.emit({
              costCodeId: null,
              costCodeName: res.data.name,
              catnum: res.data.catnum,
              extraNum: null,
            });

            this.costCodeSelected.emit({
              costCodeId: res.data.cost_code_id,
              costCodeName: null,
              catnum: res.data.catnum,
              extraNum: res.data.name,
            });
          }
        });
      });
  }

  async selectExtrasWithModal() {
    this.modal
      .create({
        component: CostCodePage,
        cssClass: 'my-custom-class',
        componentProps: {
          codes: this.extras,
          isCostCode: false,
        },
      })
      .then((modal) => {
        modal.present();
        modal.onDidDismiss().then((res) => {
          if (res.data !== '') {
            this.costcodes.patchValue({
              extraDropdown: res.data.cost_code_id,
            });
            this.selectedExtra = res.data.extranum;
            this.selectedExtraName = res.data.name;

            this.costCodeSelected.emit({
              costCodeId: res.data.cost_code_id,
              costCodeName: res.data.name,
              catnum: res.data.catnum,
              extraNum: res.data.extranum,
            });
          }
        });
      });
  }

  async getCategories() {
    this.util.showLoading();
    if (!this.job_id || this.job_id === 0) {
      return;
    }
    return await this.http
      .getAllCategoriesCostCode(
        this.job_id,
        this.companyNumber,
        this.phase,
        this.userDepartmentId
      )
      .subscribe(
        (response) => {
          this.util.hideLoading();
          this.costCodes = response['data'];
          if (this.costCodes && this.costCodes.length == 1) {
            this.selectedCostCode = this.costCodes[0]['catnum'];
            this.selectedCostCodeName = this.costCodes[0]['name'];
          }
          if (this.selectedCostCode && this.selectedCostCode != '') {
            this.getExtras();
          }
        },
        (err) => {
          this.util.hideLoading();
          this.util.showInfoBox(err.error.errors.message);
        }
      );
  }

  async getExtras() {
    this.util.showLoading();
    return await this.http
      .getExtraCategories(
        this.job_id,
        this.companyNumber,
        this.phase,
        this.selectedCostCode
      )
      .subscribe(
        (response) => {
          this.util.hideLoading();
          this.extras = response['data'];
          if (this.extras && this.extras.length == 1) {
            this.selectedExtra = this.extras[0]['extranum'];
            this.selectedExtraName = this.extras[0]['name'];
            this.costCodeSelected.emit({
              costCodeId: this.extras[0]['cost_code_id'] as any,
              costCodeName: this.selectedExtraName as any,
              catnum: null,
              extraNum: this.selectedExtra as any,
            });
          }
        },
        (err) => {
          this.util.hideLoading();
          this.util.showInfoBox(err.error.errors.message);
        }
      );
  }

  async onPhaseSelect(data) {
    if (data) {
      if (data.detail.value !== this.phase) {
        this.phase = data.detail.value;
        const selectedPhase = this.phases.filter(
          (f) => f.phasenum === (this.phase as any)
        );
        this.phaseSelected.emit({ phase: selectedPhase });

        this.selectedCostCode = '';
        this.selectedCostCodeName = '';
        this.costCodes = [];

        this.selectedExtra = '';
        this.selectedExtraName = '';
        this.extras = [];
      }

      //get the list of categories to display
      await this.getCategories();
    }
  }

  ngOnDestroy() {
    this.$ngUnsubscribe.next();
    this.$ngUnsubscribe.complete();
  }

  getUserInfo() {
    this.authServ.getUserInfo().then((res) => {
      this.userDepartmentId = res.department_id;
    });
  }
}
