import { Component } from '@angular/core';

import { Platform, MenuController, NavController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthService } from 'src/app/common/services/auth.service';

import { Plugins } from '@capacitor/core';
import { Subscription } from 'rxjs';
import { Constants } from './common/constants/constant';
import * as moment from 'moment';
import { SocketService } from './common/services/socket.service';
import { HttpBaseService } from './common/services/http-base.service';
const { Keyboard } = Plugins;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  // rootPage: any = HomePage;
  pages: any = [];
  isApp: any;
  timShetURL = '';
  pyPeriodURL = '';
  subscription: Subscription;
  formData: any;
  currentUserRole: any;
  userId: any;
  menuItems: any = [];
  dateToday = moment();
  timeEntryUrl = '';
  manpowerUrl = '';
  currentUser;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private menu: MenuController,
    private authServ: AuthService,
    private navCtrl: NavController // private backgroundMode: BackgroundMode
  ) {
    this.authServ.getUserInfo().then((res) => {
      this.currentUser = res;
      if (res) {
        //set url paths based on permission not role
        this.authServ.getPermissions().then((permRes) => {
          let permissions = JSON.parse(permRes);
          let viewForemanTime = false,
            viewWorkOrder = false,
            viewManpower = false,
            viewPurchaseOrders = false,
            viewCommunications = false,
            viewForms = false;
          for (let i = 0; i < permissions.length; i++) {
            let id = permissions[i].permission_id;
            var view = permissions[i].permission_view_option;
            var edit = permissions[i].permission_edit_option;

            switch (id) {
              case 1:
                viewForemanTime =
                  view == 'My Supervisees' || view == 'All' ? true : false;
                break;
              case 4:
                viewWorkOrder = view == 'Yes' ? true : false;
                break;
              case 5:
                viewManpower = view != 'None' ? true : false;
                break;
              case 12:
                viewPurchaseOrders = view != 'None' ? true : false;
                break;
              case 13:
                viewCommunications = view == 'Yes' ? true : false;
                break;
              case 14:
                viewForms = view == 'Yes' ? true : false;
            }
          }

          if (viewForemanTime) {
            this.timShetURL =
              '/timesheet?entry_date=' + this.dateToday.format('YYYY-MM-DD');
            this.pyPeriodURL = '/payperiod';
            this.timeEntryUrl = '/newtimeentry';
            this.manpowerUrl = '/manpower';
          } else {
            this.timShetURL =
              '/timesheet/timesheetdetails?role=' +
              res.role +
              '&employee_id=' +
              res.id +
              '&entry_date=' +
              this.dateToday.format('YYYY-MM-DD') +
              '&emp_fname=' +
              res.firstname +
              '&emp_lname=' +
              res.lastname;
            this.pyPeriodURL = '/payperiod?empId=' + res.id;
            this.timeEntryUrl = '/newtimeentry?employee_id=' + res.id;
          }

          this.userId = res.id;
          this.currentUserRole = res.role;

          this.menuItems = [
            {
              title: 'Home',
              url: '/home',
              icon: 'home',
              src: '../assets/imgs/home_ic.jpg',
              classs: '',
            },
            {
              title: 'Timesheet',
              icon: 'clock',
              url: this.timShetURL,
              src: '../assets/imgs/dailytimesheet_ic.jpg',
              classs: 'smallIcon',
            },
            {
              title: 'Pay Period Totals',
              icon: 'calendar-alt',
              url: this.pyPeriodURL,
              src: '../assets/imgs/timesheet_ic.jpg',
              classs: '',
            },
          ];

          if (viewManpower) {
            this.menuItems.push({
              title: 'Manpower',
              icon: 'users-cog',
              url: '/manpower',
              src: '',
              classs: '',
            });
          }

          if (viewWorkOrder) {
            this.menuItems.push({
              title: 'Work Orders',
              icon: 'wrench',
              url: '/workorders',
              src: '',
              classs: '',
            });
          }

          if (viewPurchaseOrders) {
            this.menuItems.push({
              title: 'Purchase Orders',
              icon: '',
              url: '/purchaseorder',
              src: '../assets/imgs/purchase_orders.svg',
              classs: '',
            });
          }

          if (viewCommunications) {
            this.menuItems.push({
              title: 'Communication',
              icon: 'comments',
              url: '/communication',
              src: '',
              classs: '',
            });
          }

          if (viewForms) {
            this.menuItems.push({
              title: 'Forms',
              icon: 'file',
              url: '/forms',
              src: '',
              classs: '',
            });
          }

          this.menuItems.push(
            {
              title: 'My Account',
              icon: 'user-alt',
              url: '/myaccount',
              src: '../assets/imgs/account.png',
              classs: '',
            },
            {
              title: 'Log Out',
              icon: 'sign-out-alt',
              url: '/auth',
              src: '../assets/imgs/logout.png',
              classs: '',
            }
          );

          this.pages = [, , ,];

          for (let i = 0; i < this.pages.length; i++) {
            let pageTitle = this.pages[i].title;

            switch (pageTitle) {
              case 'Work Orders':
                if (viewWorkOrder) {
                  this.menuItems.push(this.pages[i]);
                }
                break;

              case 'Manpower':
                if (viewManpower) {
                  this.menuItems.push(this.pages[i]);
                }
                break;

              case 'Purchase Orders':
                if (viewPurchaseOrders) {
                  this.menuItems.push(this.pages[i]);
                }
                break;

              case 'Communications':
                break;

              case 'Time Entry':
                break;

              default:
                this.menuItems.push(this.pages[i]);
            }
          }
        });
      }
    });

    this.isApp =
      !document.URL.startsWith('http') ||
      document.URL.startsWith('http://localhost:8080');
    this.initializeApp();
    // document.body.classList.toggle('dark', true);
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      if (this.isApp) {
        Keyboard.setAccessoryBarVisible({ isVisible: false });
      }
    });
    if (this.platform.is('android') || this.platform.is('ios')) {
      // this.setUpOneSignal();
    }
  }

  closeMenu(url = '', page = '') {
    if (url !== '') {
      this.navCtrl.navigateForward(url);
    }
    if (page === 'Log Out') {
      this.authServ.clearStorage();
    }
    this.menu.close();
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }
}
