import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort',
})
export class SortPipe implements PipeTransform {
  transform(value: any[], propertyName: string): any[] {
    if (propertyName) {
      return value.sort((b: any, a: any) =>
        b[propertyName].localeCompare(a[propertyName])
      );
    } else {
      return value;
    }
  }
}
