import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { Subject } from 'rxjs';

const { Storage } = Plugins;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  authenticatedUserId = new Subject<any>();
  userInfoUpdate = new Subject<any>();
  constructor() {}

  setToken(token, tokenExpiry) {
    Storage.set({
      key: 'token',
      value: token,
    });

    Storage.set({
      key: 'tokenExpiry',
      value: tokenExpiry,
    });
  }

  setDeviceToken(deviceToken) {
    Storage.set({
      key: 'deviceToken',
      value: deviceToken,
    });
  }

  async getDeviceToken() {
    const { value } = await Storage.get({ key: 'deviceToken' });
    return value;
  }

  async getToken() {
    const { value } = await Storage.get({ key: 'token' });
    return value;
  }

  async getUserToken() {
    const { value } = await Storage.get({ key: 'token' });
    return value;
  }

  setUserInfo(userInfo) {
    Storage.set({
      key: 'user',
      value: JSON.stringify(userInfo),
    });
    this.authenticatedUserId.next(userInfo.id);
    this.userInfoUpdate.next(userInfo);
  }

  setPermissions(permissions) {
    Storage.set({
      key: 'permissions',
      value: JSON.stringify(permissions),
    });
  }

  setRememberMe(credentials) {
    Storage.set({
      key: 'remember_me',
      value: JSON.stringify(credentials),
    });
  }

  async getRememberMe() {
    const { value } = await Storage.get({ key: 'remember_me' });
    return JSON.parse(value);
  }

  async removeRememberMe() {
    await Storage.remove({ key: 'remember_me' });
  }

  async setNotificationInfo(data) {
    Storage.set({
      key: 'not',
      value: JSON.stringify(data),
    });
  }

  async getNotificationInfo() {
    const { value } = await Storage.get({ key: 'not' });
    return JSON.parse(value);
  }

  setAllUserList(users) {
    Storage.set({
      key: 'usersList',
      value: JSON.stringify(users),
    });
  }

  async getUserInfo() {
    const { value } = await Storage.get({ key: 'user' });
    return JSON.parse(value);
  }

  async getPermissions() {
    const { value } = await Storage.get({ key: 'permissions' });
    return JSON.parse(value);
  }

  async getUsersList() {
    const { value } = await Storage.get({ key: 'usersList' });
    return JSON.parse(value);
  }

  async clearStorage() {
    const { value } = await Storage.get({ key: 'remember_me' });
    await Storage.clear();
    Storage.set({
      key: 'remember_me',
      value: value,
    });
  }
}
